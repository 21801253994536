body{
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px;
    margin: 0px;
}

.coega-blue {
    background-color: #232f55;
}

.no-overflow-x{
    overflow-x: hidden;
}

.no-overflow-y{
    overflow-y: hidden;
}

.no-overflow{
    overflow: hidden;
}
.m-l-5{
    margin-left: -5px;
}
/*
.bg-img{
    background-image:url('../images/bg-overlay.png'), linear-gradient(to right,rgba(35,46,84,1),rgba(48,68,137,0.75)), url('../images/background.jpg') ;
    width: 100%;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom,bottom;
    background-position-x: right,right;
    background-color: #5F73A4;
}
*/

@media (min-width: 992px){
    .bg-img{
        background-image:url('../images/bg-overlay.png'), linear-gradient(to right,rgba(35,46,84,1),rgba(48,68,137,0.75)), url('../images/background.jpg') ;
        width: 100%;
        height: 100vh;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: right;
        background-color: #5F73A4;
    }
}
@media (max-width: 991px) {
    .bg-img{
        background-image:url('../images/bg-overlay_small.png'), linear-gradient(to right,rgba(35,46,84,1),rgba(48,68,137,0.75)), url('../images/background.jpg') ;
        width: 100%;
        height: 100vh;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: bottom,bottom;
        background-position-x: right,right;
        background-color: #5F73A4;
    }
}



.bg-overlay{
    background-color:#1B2F7D;
    width: 100%;
    height: 100vh;
    position: absolute;
    opacity: 0.7;
}

.logo-container {
    position:absolute;
    top: 15%;
}
@media (max-width:768px){.logo-container{top:3%;}}
.logo-container img {
    width: 70%;
}

.logo-container p {
    font-size:15pt
}

.main-sub-head {
    font-weight: bold;
    font-size: 20pt;
    color: #FFF;
}

.center{
    text-align: center;
}
.left{
    text-align: left;
}
.right{
    text-align: right;
}

.txt-bold{
    font-weight: bold;
}

.col-blue{
    color: #006ABB;
}
.col-green{
    color: #00993B;
}
.col-yellow{
    color: #FBB400;
}
.col-white{
    color: #FFF;
}
.mid{
    top: -.25em;
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.zone-1-color{
    background-color: #7AAEDF;
}
.zone-2-color{
    background-color: #0A6C43;
}
.zone-3-color{
    background-color: #818285;
}
.zone-4-color{
    background-color: #548AA7;
}
.zone-5-color{
    background-color: #A7A9AC;
}
.zone-6-color{
    background-color: #117CC1;
}
.zone-7-color{
    background-color: #F38F27;
}
.zone-8-color{
    background-color: #45AD4A;
}
.zone-9-color{
    background-color: #DC644C;
}
.zone-10-color{
    background-color: #B4D3E9;
}
.zone-11-color{
    background-color: #1AAD86;
}
.zone-12-color{
    background-color: #599EB9;
}
.zone-13-color{
    background-color: #D3252B;
}
.zone-14-color{
    background-color: #5A595B;
}
.zone-nmblp-color{
    background-color: #231F20;
}

.side-bar{
    background-color: #123073;
    z-index: 6;
}

.bg-coega {
    background-color: #253158;
}

.color{
    color: #7AAEDF;
}
.logo-holder {
    padding-top: 3vh;
    height: 25vh;
    color: white;
    font-size: 85%;
    box-shadow: -2px 10px 9px -6px #092770;
}
.navbar-brand{
    padding-bottom: 0;
    padding-top: 0;
}
.navbar-brand img{
    height: 2.5rem;
}

@media (max-width: 991px)
{
    .side-bar{
        position: fixed;
        top: 56px;
        left: -80%;
        width: 80%;
    }

    .side-bar.open{
        left: 0;
    }
    
    .zone-info-active{
        position: fixed;
        top: 56px;
        left: -80%;
        width: 80%;
    }
    .zone-info-inactive {
        position: fixed;
        top: 56px;
        left: -80%;
        width: 80%;
    }

    .side-nav{
        height: calc( 100vh - 3.5rem );
        overflow: auto;
        padding-top: 10px;
    }

    .logo-holder{
        display: none;
    }
    /*.logo-holder {
        height: auto;
        padding-top: 5vh;
    }*/
}
@media (min-width: 992px)
{
    .top-bar{
        display: none;
    }
    
    .side-nav{
        overflow-y: auto;
        height: 75vh;
        padding-top: 10px;
    }
}
.button {
    cursor: pointer;
}
.wire-button{
    border: solid 1px #9e9e9e;
    text-align: center;
    font-weight: bold;
    color: white;
    padding: 5px 5px;
    width: 80%;
    margin: 0px auto 10px auto;
    border-radius: 10px !important;

    background: rgb(245,245,245);
    background: -moz-linear-gradient(125deg, rgba(245,245,245,0.20015756302521013) 0%, rgba(248,249,249,0) 100%);
    background: -webkit-linear-gradient(125deg, rgba(245,245,245,0.20015756302521013) 0%, rgba(248,249,249,0) 100%);
    background: linear-gradient(125deg, rgba(245,245,245,0.20015756302521013) 0%, rgba(248,249,249,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#f8f9f9",GradientType=1);
}
.wire-button.active {
    color: #fff;
    background: none !important;
    background: #11111101 !important;
    border: solid 2px white;
}
.hide-media-button{
    background-color: white;
    border-radius: 8px;
    font-size: 8pt;
    width: 60px;
    text-align: center;
    padding: 5px;
    position: absolute;
    bottom: 2.5%;
    right: 2.5%;
}

.zone-info-contanier{
    background-color: rgba(250, 250, 250, 0.9);
    border-bottom-right-radius: 20px;
    font-size: 8pt;
}
.pulse{

    box-shadow: 0 0 0 rgba(18, 48, 115, 0.5);
    animation: pulse 2s 3;
}
.pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(18, 48, 115, 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
      box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(18, 48, 115, 0.5);
        box-shadow: 0 0 0 10px rgba(18, 48, 115, 0.5);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
        box-shadow: 0 0 0 0 rgba(18, 48, 115, 0.5);
    }
  }

@media (min-width: 992px)
{
    .zone-info-contanier{
        overflow-y: auto;
        max-height: 75vh;
        margin-top: 25vh;
        z-index: 2;
    }
}
@media (min-width: 992px)
{
    .zone-info-inactive{
        left: -100%;
        transition: left 500ms ease-in-out;
    }
    .zone-info-active{
        left: 0px;
        transition: left 500ms ease-in-out;
    }
}
@media (max-width: 991px)
{
    .zone-info-inactive{
        left: 0px;
        display: none;
    }
    .zone-info-active{
        left: 0px;
        display: none;
    }
}
.company-info-container{
    padding: 14px 0;
    border-top: 1px solid rgba(128, 128, 128, 0.2);
}

.company-info-container img{
    width: 5rem;
    margin-bottom: 5px;
}
.company-info-container .more-info-button{
    text-transform: capitalize;
    padding: 3px;
    margin: 5px 0px 20px 0px;
    cursor: pointer;
}

.company-info-container .detailed-info-container .site-view-button{
    background-color: black;
    text-transform: capitalize;
    padding: 3px;
    margin: 5px 0px;
    cursor: pointer;
}

@media (max-width: 991px)
{
    .company-info-container .detailed-info-container .site-view-button{
        padding: 4px;
        margin: 7px 0px;
        font-size: 10pt;
    }
    .company-info-container .more-info-button{
        padding: 4px;
        margin: 7px 0px 20px 0px;
        font-size: 10pt;
    }
}

.company-info-container .detailed-info-container .company-description{
    margin: 10px 0px 10px 0px;
}

.zone-media-container{
    background-color: rgba(250, 250, 250, 0.6);
    border-radius: 20px;
    text-align: center;
    max-height: 75vh;
    z-index: 1;
}
@media (max-width: 991px){
    .zone-media-container{
        height: 80vh;
    }
}
@media (min-width: 992px){
    .zone-media-container{
        margin-top: 25vh;
        padding-top:1%;
    }
}
.zone-media-container iframe{
    height: 98%;
    width: 100%;
    border-radius: 12px;
}

@media (min-width: 992px){
    .zone-media-inactive{
        left: -100%;
        transition: all 500ms ease-in-out;
    }
    .zone-media-active{
        left: 0px;
        transition: all 500ms ease-in-out;
    }
}

@media (max-width: 991px){
    .zone-media-inactive{
        display: none;
    }
    .zone-media-active{
        display: block;
    }
}

.inactive{
    display: none;
}
.active{
    display: block;
}

.hide-info{
    background-color: #89AC97 !important;
}

.map-block{
    height: 200px;
    width: 200px;
}

/*.map-block:nth-child(1){
    background-color: #1B2F7D;
}
.map-block:nth-child(2){
    background-color:#FBB400;
}
.map-block:nth-child(3){
    background-color:#00993B;
}
.map-block:nth-child(4){
    background-color:#89AC97;
}*/

.map-grid-container .row{
    flex-wrap: nowrap;
}
#map-layer{
    width: 100vw;
    height: 80vh;
    background-size: cover;
}
@media (min-width: 992px)
{
    #map-layer{
        top: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        height: 100vh;
    }
}
@media (max-width: 991px){
    #map-layer {
        right: inherit;
        bottom: inherit;
        height: calc( 100vh - 3.5rem );
    }
}
/*#map-layer{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}*/

.map{
    width: 100%;
    height: 100%;
}

/*
#map-grid-container{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
*/

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0;
    padding-right: 0.5rem;
    border-bottom: none;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.center-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.2rem 1rem 0.2rem;
    border: none;
}
.modal-control{
    height: 3rem;
}
.modal-control img{
    height: 2.5rem;
    width: 2.5rem
}

.modal-control img:hover{
    border-radius: 50%;
    background-image:
    radial-gradient(
      rgba(36, 36, 36, 0.3),
      rgba(0, 0, 0, 0.3)
    );
}

.modal-control.inactive{
    opacity: .65;
}

.nav-control{
    height: 2rem;
}
.nav-control img{
    height: 2rem;
    width: 2rem;
}

.comp-btn{
    cursor: pointer;
    padding: 0;
}

.comp-btn img{
    border-radius: 50%;
    background-image:
    radial-gradient(
      rgba(36, 36, 36, 0.1),
      rgba(0, 0, 0, 0.1)
    );
}
.comp-btn img:hover{
    background-image:
    radial-gradient(
      rgba(36, 36, 36, 0.3),
      rgba(0, 0, 0, 0.3)
    );
}

.comp-logo-img {
    overflow: hidden;
    height: 6rem;
}
.comp-logo-img img{
    max-width: 100%;
    height: 12rem;
    margin: -20% 0;
}

.zone-info-header{
    line-height: 1.5rem;
    padding: 0.5rem 0.2rem;
    text-align: center;
    font-weight: 700!important;
}

.comp-info-header {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.info-blocks {
    min-height: 6rem;
    line-height: 1.1rem;
}

.info-blocks .info-block {
    padding-top: 15px;
    border: 1px solid rgba(128, 128, 128, 0.5);
}

.info-blocks .color{
    color: #212529;
    background-color: #FABC09;
}

@media (min-width: 992px){
    
    .info-blocks .color{
        border-right: 2px solid white;
    }
}

.invest-back{
    background: url('../../res/images/investor.png') bottom right no-repeat;
    background-size: contain;
}

.sector-back{
    background: url('../../res/images/sector.png') bottom right no-repeat;
    background-size: contain;
}

.country-back{
    background: url('../../res/images/country.png') bottom right no-repeat;
    background-size: contain;
}

.Belgium{
    background: url('../../res/images/flags/Belgium.png') bottom right no-repeat;
    background-size: contain;
}
.China{
    background: url('../../res/images/flags/China.png') bottom right no-repeat;
    background-size: contain;
}
.Denmark{
    background: url('../../res/images/flags/Denmark.png') bottom right no-repeat;
    background-size: contain;
}
.France{
    background: url('../../res/images/flags/France.png') bottom right no-repeat;
    background-size: contain;
}
.Germany{
    background: url('../../res/images/flags/Germany.png') bottom right no-repeat;
    background-size: contain;
}
.India{
    background: url('../../res/images/flags/India.png') bottom right no-repeat;
    background-size: contain;
}
.Italy{
    background: url('../../res/images/flags/Italy.png') bottom right no-repeat;
    background-size: contain;
}
.Japan{
    background: url('../../res/images/flags/Japan.png') bottom right no-repeat;
    background-size: contain;
}
.South_Africa{
    background: url('../../res/images/flags/South_Africa.png') bottom right no-repeat;
    background-size: contain;
}
.South_Africa_Germany{
    background: url('../../res/images/flags/South_Africa_Germany.png') bottom right no-repeat;
    background-size: contain;
}
.Spain{
    background: url('../../res/images/flags/Spain.png') bottom right no-repeat;
    background-size: contain;
}

@media (min-width: 768px) and (max-width: 1199px){
    
    .invest-back, .sector-back, .country-back{
        background: none;
    }
}

.info-blocks .comp-invest-amount {
    font-size: 1.5rem;
    line-height: 1.6rem;
}

.info-blocks .comp-base {
    font-size: .9rem;
    line-height: 1rem;
}

.info-blocks .comp-value {
    font-size: 1.2rem;
    line-height: 1.3rem;
    width: 60%;
}
.comp-products-label {
    color: #FABC09;
}
#comp-products{
    width: 100% !important;
}

#panoramicMedia, #videoMedia {
    height: 98%;
    min-height: 600px;
    width: 100%;
}
.modal-body{
    max-height: 635px;
}
.hidden{
    display: none;
}

.measureDiv {
    z-index: 5;
    position: absolute;
    right: 40px;
    top: 40px;
}
@media (max-width: 991px){
    .measureDiv{
        top: 60px;
    }
}

#measureBtn{
    position: relative;
    float: left;
    margin-left: 5px;
    width: 50px;
    height: 50px;
    background-color: #123073;
    border: solid 1px #123073;
}
#clearAllMeasure, #measureInfo{
    display: none;
    position: relative;
    float: left;
    margin-left: 5px;
    width: 50px;
    height: 50px;
    color: white;
    background-color: #123073;
    padding: 5px;
    font: 35px/40px Arial;
    text-align: center;
    vertical-align: middle;
    border: none;
}
#measureBtn img{
    padding: 5px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
}
#measureBtn.active{
    background: none;
}
#clearAllMeasure.active, #measureInfo{
    display: block;
}
.map-controls, .ol-zoom{
    z-index: 5;
    position: absolute;
    right: 40px;
    bottom: 40px;
}


.map-control-button, .ol-zoom-in, .ol-zoom-out{
    position: relative;
    float: left;
    margin-left: 5px;
    min-width: 50px;
    color: white;
    background-color: #123073;
    padding: 5px;
    font: 35px/40px Arial;
    text-align: center;
    vertical-align: middle;
    border: none;
}
#clearAllMeasure, #measureInfo, #measureBtn, .ol-zoom-in:focus, .ol-zoom-out:focus{
    outline: none;
}
@media (max-width: 991px){
    #clearAllMeasure, #measureInfo, .map-control-button, .ol-zoom-in, .ol-zoom-out{
        min-width: 40px;
        font: 30px/35px Arial;
    }

    .map-controls, .ol-zoom{
        display: none;
    }
}
#layer-select{
    z-index: 3;
    top: 0;
    right: 0;
    position: fixed;
}

.cluster{
    display: block;
    font-size: .8rem;
}

.zone{
    display: block;
    font-size: .7rem;
    font-weight: normal;
    /*text-transform: uppercase;*/
}

.ol-attribution, .ol-rotate{
    display:none;
}

.clickable{
    cursor: pointer;
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
.embed-container iframe, 
.embed-container object, 
.embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }